import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// Import tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral.js'
import * as breakpoint from '../../assets/styles/tokens/token-breakpoints.js'
import * as fontWeight from '../../assets/styles/tokens/token-font-weight.js'
import * as fontSize from '../../assets/styles/tokens/token-font-size.js'

const JumbotronHeader = styled.header`
  width: 100%;
  padding: 0 0.75rem;
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: -5rem;

  @media (min-width: ${breakpoint.BREAKPOINT_2}) {
    top: -10rem;
  }
`

const JumbotronTitle = styled.h1`
  color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
  font-size: 9vw;
  font-weight: ${fontWeight.FONT_WEIGHT_5};
  line-height: 1.25;

  @media (min-width: ${breakpoint.BREAKPOINT_1}) {
    font-size: ${fontSize.FONT_SIZE_6};
  }

  @media (min-width: ${breakpoint.BREAKPOINT_2}) {
    font-size: ${fontSize.FONT_SIZE_8};
  }

  @media (min-width: ${breakpoint.BREAKPOINT_3}) {
    font-size: ${fontSize.FONT_SIZE_9};
  }
`

const OdpJumbotron = ({ children }) => {
  return (
    <JumbotronHeader>
      <JumbotronTitle>{children}</JumbotronTitle>
    </JumbotronHeader>
  )
}

JumbotronTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OdpJumbotron
