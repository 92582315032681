import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// Tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'
import * as colorBrandGreen from '../../assets/styles/tokens/token-color-brand-green.js'
import * as borderRadius from '../../assets/styles/tokens/token-border-radius'

// Ant Design
import { Icon } from 'antd'

const OdpSearchContainer = styled.div`
  padding-right: 1rem;
  padding-left: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colorNeutral.COLOR_NEUTRAL_TINT_75};
  border-radius: ${borderRadius.BORDER_RADIUS_6};
  transition: border-color 300ms ease-in-out;

  :hover {
    border-color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
  }

  i {
    margin-left: 0 !important;
  }
`

const OdpSearchContainerInput = styled.input`
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  margin-right: 0.5rem;
  color: ${colorNeutral.COLOR_NEUTRAL_TINT_15};
  border: none;
  border-top-left-radius: ${borderRadius.BORDER_RADIUS_6};
  border-bottom-left-radius: ${borderRadius.BORDER_RADIUS_6};
  background-color: transparent;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colorNeutral.COLOR_NEUTRAL_TINT_15};
  }
  :-ms-input-placeholder {
    color: ${colorNeutral.COLOR_NEUTRAL_TINT_15};
  }

  :focus {
    outline: none;
  }
`

const OdpSearch = props => {
  const { placeholder, iconSize, ...rest } = props

  return (
    <OdpSearchContainer {...rest}>
      <OdpSearchContainerInput placeholder={placeholder} />
      <Icon
        type="search"
        style={{
          fontSize: iconSize,
          color: colorNeutral.COLOR_NEUTRAL_TINT_15,
        }}
      />
    </OdpSearchContainer>
  )
}

OdpSearch.propTypes = {
  placeholder: PropTypes.string,
  iconSize: PropTypes.string,
}

export default OdpSearch
