import greatBarrierReef from '../../images/explore/great-barrier-reef.jpg'
import underwaterNoise from '../../images/explore/underwater-noise.jpg'
import oceanCleanup from '../../images/explore/ocean-cleanup.jpg'
import revOceanTriton from '../../images/explore/rev-ocean-triton.jpg'
import davidAttenborough from '../../images/explore/david-attenborough.jpg'
import untappedPotential from '../../images/explore/untapped-potential.jpg'

export const LATEST = [
  {
    url: '/explore/great-barrier-reef',
    headerText: 'Challenges and solutions of the Great Barrier Reef',
    subHeaderText: 'Monitoring the reef',
    imgUrl: greatBarrierReef,
    supportingText: 'Oct 20, 2019',
  },
  {
    headerText: 'Untapped potential of citizen science to track SDG',
    subHeaderText: 'UN Environment Program',
    imgUrl: untappedPotential,
    supportingText: 'Oct 11, 2019',
  },
  {
    headerText: 'Ocean cleanup device collects plastic for the first time',
    subHeaderText: 'Ocean Cleanup',
    imgUrl: oceanCleanup,
    supportingText: 'Oct 03, 2019',
  },
]
export const FEATURED = [
  {
    headerText: `Underwater noise pollution is at 'critical stage'`,
    subHeaderText: 'Euronews',
    imgUrl: underwaterNoise,
    supportingText: 'Sep 21, 2019',
  },
  {
    headerText: 'REV Ocean secure first-ever Triton submersible',
    subHeaderText: 'Rev Ocean',
    imgUrl: revOceanTriton,
    supportingText: 'Sep 20, 2019',
  },
  {
    headerText: `Sir David Attenborough's message to world leaders`,
    subHeaderText: 'World Wildlife Foundation',
    imgUrl: davidAttenborough,
    supportingText: 'Sep 24, 2019',
  },
]
export const DATA_TYPES = [
  { headerText: 'Examine the Great Barrier Reef' },
  { headerText: 'Ocean-based renewable energy' },
  { headerText: 'Ocean-based renewable energy' },
]
export const DATA_PARTNERS = [
  { headerText: 'Examine the Great Barrier Reef' },
  { headerText: 'Ocean-based renewable energy' },
  { headerText: 'Ocean-based renewable energy' },
]
