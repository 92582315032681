import React, { useEffect, useState, useRef } from 'react'
import { hot } from 'react-hot-loader/root'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

// Tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'
import * as colorBrandGreen from '../../assets/styles/tokens/token-color-brand-green.js'
import * as borderRadius from '../../assets/styles/tokens/token-border-radius'
import * as fontWeight from '../../assets/styles/tokens/token-font-weight'
import * as zIndex from '../../assets/styles/tokens/token-z-index'

// React version fo Cesium
import { Globe, CameraFlyTo, ImageryLayer } from 'resium'

// Cesium
import { Cartesian3, SingleTileImageryProvider, Rectangle } from 'cesium'

// Custom components
import OdpGlobalViewer from '../../components/GlobalViewer/OdpGlobalViewer'
import OdpZoom from '../../components/Zoom/OdpZoom'
import OdpmapPreview from '../../components/mapPreview/OdpmapPreview'
import OdpDataProvidersActiveList from '../../components/DataProvidersActiveList/OdpDataProvidersActiveList'
import OdpTerms from '../../components/Terms/OdpTerms'
import OdpSearch from '../../components/Search/OdpSearch'
import OdpSwitch from '../../components/Form/OdpSwitch'
import OdpCheckbox from '../../components/Form/OdpCheckbox'
import OdpProviderCheckBox from '../../components/Checkbox/OdpProviderCheckbox'
import OdpCaseSidebarTitle from '../../components/CaseSidebarTitle/OdpCaseSidebarTitle'
import OdpCaseDataProviders from '../../components/CaseDataProviders/OdpCaseDataProviders'
import OdpCaseRelatedReports from '../../components/CaseRelatedReports/OdpCaseRelatedReports'
import OdpCaseCommunity from '../../components/CaseCommunity/OdpCaseCommunity'
import OdpCaseRelated from '../../components/CaseRelated/OdpCaseRelated'

// Ant Design
import { Icon } from 'antd'

// Images
import OdpBenthic from '../../assets/images/benthic-overlay-01.png'
import OdpBathymerty from '../../assets/images/bathymerty-overlay-01.png'
import OdpEarlyData from '../../assets/images/case/img-early-data.png'
import OdpInsideThe from '../../assets/images/case/img-inside-the.png'
import { ReactComponent as OdpSoundscape } from '../../assets/images/sidebar-icons/soundscape.svg'
import { ReactComponent as OdpOdfLogoElement } from '../../assets/images/odf-logo-element.svg'

// Menu items
import DATA_PROVIDERS from '../../assets/data/greatBarrierReef/greatBarrierDataProviders.array'

// Cesium configs - Fly to starting point
const positionCameraFlyTo = Cartesian3.fromDegrees(
  147.29514,
  -16.03048,
  1000000
)

// NOTE: Header component
// ----------------------------------------------------------------------------
const OdpCaseHeader = styled.header`
  padding: 2rem 2rem 0 2rem;
  background-color: #f5f5f5;
`

const OdpCaseNav = styled.nav`
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OdpCaseNavDropdown = styled(OdpCaseNav)`
  padding-top: 1.25rem;
  margin-right: 2rem;
  margin-left: 6rem;
  justify-content: flex-start;
  border-top: 1px solid ${colorNeutral.COLOR_NEUTRAL_TINT_75};
`

const OdpCaseList = styled.ul`
  padding: 0;
  margin: 0 2rem 0 0rem;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const OdpCaseListItem = styled.li`
  padding: 0.5rem 1rem;
  margin: 0 1rem 0 0;
  border-radius: ${borderRadius.BORDER_RADIUS_6};
  background-color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.025);
`

const OdpCaseNavListItem = styled.li`
  font-size: 1rem;

  :not(:last-child) {
    margin-right: 2rem;
  }

  :last-child {
    padding: 0.6rem 1.5rem;
    border-radius: ${borderRadius.BORDER_RADIUS_6};
    border: 1px solid ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
  }
`

const OdpCaseListItemSimple = styled(OdpCaseListItem)`
  padding: 0;
  margin: 0 3rem 0 0;
  border: none;
  background-color: transparent;
  box-shadow: none;

  i {
    margin-left: 0.5rem;
  }
`

const OdpCaseListItemButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  transition: color 300ms ease-in-out;

  :hover,
  :focus {
    color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
  }

  :focus {
    outline: none;
    font-weight: ${fontWeight.FONT_WEIGHT_5};

    i {
      font-weight: ${fontWeight.FONT_WEIGHT_5};
    }
  }
`

const OdpCaseDropdown = styled.div`
  width: 240px;
  height: auto;
  padding: 0.5rem 1rem;
  margin-top: 20px;
  position: absolute;
  border-radius: 4px;
  background-color: ${colorNeutral.COLOR_NEUTRAL_TINT_95};
  z-index: ${zIndex.Z_INDEX_4};
`

const OdpCaseDropdownController = styled.div`
  margin: 1rem 0 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OdpCaseDropdownTitle = styled.h2`
  font-size: 0.75rem;
  font-weight: ${fontWeight.FONT_WEIGHT_5};
`

const OdpCaseDropdownList = styled.ul`
  padding: 0;
  margin: 0.5rem;
  list-style-type: none;
`

const OdpCaseDropdownListItem = styled.li`
  padding-left: 20px;
  margin-bottom: 0.25rem;
  text-indent: -20px;
`

const OdpCaseCustomLink = styled(Link)`
  color: ${colorNeutral.COLOR_NEUTRAL_TINT_15};

  :hover {
    color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
  }
`

// NOTE: Sidebar component
// ----------------------------------------------------------------------------
const OdpCaseSidebar = styled.aside`
  position: relative;
`

const OdpCaseSidebarContent = styled.div`
  height: calc(100vh - 150px);
  width: 400px;
  padding: 1rem;
  background-color: #f5f5f5;
  overflow-y: scroll;
`

const OdpCaseSidebarButton = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  margin-top: -12px;
  color: ${colorNeutral.COLOR_NEUTRAL_TINT_15};
  position: absolute;
  top: 50%;
  right: -24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0;
  background-color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: ${zIndex.Z_INDEX_3};
  transition: background-color 300ms ease-in-out;

  :focus {
    outline: none;
  }
`

const OdpCaseSidebarList = styled.ul`
  padding: 0;
  list-style-type: none;
`

const OdpCaseSidebarListItem = styled.li`
  width: 100%;
  padding: 0.25rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colorNeutral.COLOR_NEUTRAL_TINT_75};
`

const OdpCaseSidebarListItemController = styled.div`
  width: 60px;
  display: flex;
  justify-content: space-between;
`

const GreatBarrierReef = () => {
  // Header component
  // ----------------------------------------------------------------------------
  const [showDropdownDataProviders, setshowDropdownDataProviders] = useState(
    false
  )
  const [showSidebar, setshowSidebar] = useState(false)
  const [checkedBathymetry, setCheckedBathymetry] = useState(false)
  const [checkedBenthic, setCheckedBenthic] = useState(false)
  const [isChecked, setIsChecked] = useState(true)

  const node = useRef()

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setshowDropdownDataProviders(false)
  }

  const handleChange = event => {
    setIsChecked(prevCheckedValue => !prevCheckedValue)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  // Checkbox logic
  // ----------------------------------------------------------------------------
  const [selectedProviders, setSelectedProviders] = useState([
    ...DATA_PROVIDERS.map(provider => ({ ...provider, checked: false })),
  ])

  const handleProviderChange = event => {
    let providers = selectedProviders
    providers.forEach(provider => {
      if (provider.label === event.target.value) {
        provider.checked = event.target.checked
      }
    })
    setSelectedProviders(selectedProviders => [...providers])
  }

  const showProvidersList = (element, index, array) => {
    return element.checked === true
  }

  return (
    <>
      {/* Header component
          ---------------------------------------------------------------------------- */}
      <OdpCaseHeader>
        <OdpCaseNav>
          <div style={{ display: 'inline-flex' }}>
            <Link to="/">
              <OdpOdfLogoElement />
            </Link>
            <OdpSearch
              placeholder="Great Barrier Reef"
              iconSize="1.5rem"
              style={{
                width: '390px',
                marginLeft: '3rem',
                fontSize: '1.125rem',
              }}
            />
          </div>
          <OdpCaseList>
            <OdpCaseNavListItem>Join the Community</OdpCaseNavListItem>
            <OdpCaseNavListItem>Sign In</OdpCaseNavListItem>
          </OdpCaseList>
        </OdpCaseNav>
        <OdpCaseNav>
          <OdpCaseList style={{ marginLeft: '6rem' }}>
            <OdpCaseListItemSimple>
              <strong>Filter By</strong>
            </OdpCaseListItemSimple>
            <OdpCaseListItemSimple>
              <label htmlFor="check_1">
                <OdpCheckbox
                  id="check_1"
                  name="check_1"
                  onChange={handleChange}
                />
                Climate
              </label>
            </OdpCaseListItemSimple>
            <OdpCaseListItemSimple>
              <label htmlFor="check_1">
                <OdpCheckbox
                  id="check_1"
                  name="check_1"
                  checked={isChecked}
                  onChange={handleChange}
                />
                Ocean Health
              </label>
            </OdpCaseListItemSimple>
            <OdpCaseListItemSimple>
              <label htmlFor="check_2">
                <OdpCheckbox
                  id="check_2"
                  name="check_2"
                  checked={isChecked}
                  onChange={handleChange}
                />
                Operational Ocean Service
              </label>
            </OdpCaseListItemSimple>
          </OdpCaseList>
        </OdpCaseNav>
        <OdpCaseNavDropdown>
          <OdpCaseList ref={node}>
            <OdpCaseListItemSimple>
              <OdpCaseListItemButton
                onClick={() =>
                  setshowDropdownDataProviders(!showDropdownDataProviders)
                }
                style={
                  showSidebar
                    ? {
                        fontWeight: '700',
                        color: colorBrandGreen.COLOR_BRAND_GREEN_STATIC,
                      }
                    : null
                }
              >
                Data Provider (+100)
                {showDropdownDataProviders ? (
                  <Icon type="up" />
                ) : (
                  <Icon type="down" />
                )}
              </OdpCaseListItemButton>
              {showDropdownDataProviders && (
                <OdpCaseDropdown>
                  <OdpSearch
                    placeholder="Search Data Providers"
                    iconSize="1.25rem"
                    style={{
                      fontSize: '.75rem',
                    }}
                  />
                  <OdpCaseDropdownController>
                    <OdpCaseDropdownTitle>Select All</OdpCaseDropdownTitle>
                    <OdpSwitch id="switch_b_1" />
                  </OdpCaseDropdownController>
                  <OdpCaseDropdownList>
                    {selectedProviders.map((obj, index) => {
                      return (
                        <OdpCaseDropdownListItem key={index}>
                          <OdpProviderCheckBox
                            label={obj.label}
                            checked={obj.checked}
                            handleProviderChange={handleProviderChange}
                          />
                        </OdpCaseDropdownListItem>
                      )
                    })}
                  </OdpCaseDropdownList>
                </OdpCaseDropdown>
              )}
            </OdpCaseListItemSimple>
            <OdpCaseListItemSimple>
              Location
              <Icon type="down" />
            </OdpCaseListItemSimple>
            <OdpCaseListItemSimple>
              Institutes
              <Icon type="down" />
            </OdpCaseListItemSimple>
            <OdpCaseListItemSimple>
              <OdpCaseCustomLink to="/explore/great-barrier-reef-secondary">
                Sensor Platforms
                <Icon type="down" />
              </OdpCaseCustomLink>
            </OdpCaseListItemSimple>
            <OdpCaseListItemSimple>
              Others
              <Icon type="down" />
            </OdpCaseListItemSimple>
          </OdpCaseList>
        </OdpCaseNavDropdown>
      </OdpCaseHeader>

      <div style={{ display: 'flex' }}>
        {/* Sidebar component
          ---------------------------------------------------------------------------- */}
        <OdpCaseSidebar>
          <OdpCaseSidebarButton onClick={() => setshowSidebar(!showSidebar)}>
            {showSidebar ? <Icon type="left" /> : <Icon type="right" />}
          </OdpCaseSidebarButton>
          {showSidebar && (
            <OdpCaseSidebarContent>
              <OdpCaseSidebarTitle>
                Data Layers <Icon type="down" />
              </OdpCaseSidebarTitle>
              <OdpCaseSidebarList>
                <OdpCaseSidebarListItem>
                  Benthic Analysis{' '}
                  <OdpCaseSidebarListItemController>
                    <OdpSwitch
                      id="switch_1"
                      checked={checkedBenthic}
                      onChange={() => setCheckedBenthic(!checkedBenthic)}
                    />
                    <Icon type="left" />
                  </OdpCaseSidebarListItemController>
                </OdpCaseSidebarListItem>
                <OdpCaseSidebarListItem>
                  Geomorphic Analysis
                  <OdpCaseSidebarListItemController>
                    <OdpSwitch id="switch_2" />
                    <Icon type="left" />
                  </OdpCaseSidebarListItemController>
                </OdpCaseSidebarListItem>
                <OdpCaseSidebarListItem>
                  Labels
                  <OdpCaseSidebarListItemController>
                    <OdpSwitch id="switch_3" />
                  </OdpCaseSidebarListItemController>
                </OdpCaseSidebarListItem>
                <OdpCaseSidebarListItem>
                  Bathymetry
                  <OdpCaseSidebarListItemController>
                    <OdpSwitch
                      id="switch_4"
                      checked={checkedBathymetry}
                      onChange={() => setCheckedBathymetry(!checkedBathymetry)}
                    />
                  </OdpCaseSidebarListItemController>
                </OdpCaseSidebarListItem>
                <OdpCaseSidebarListItem>
                  Satellite Coral Reef Mosaic
                  <OdpCaseSidebarListItemController>
                    <OdpSwitch id="switch_5" />
                  </OdpCaseSidebarListItemController>
                </OdpCaseSidebarListItem>
                <OdpCaseSidebarListItem>
                  Coral reef watch
                  <OdpCaseSidebarListItemController>
                    <OdpSwitch id="switch_6" />
                    <Icon type="left" />
                  </OdpCaseSidebarListItemController>
                </OdpCaseSidebarListItem>
              </OdpCaseSidebarList>
              <OdpCaseSidebarTitle>From Data Providers</OdpCaseSidebarTitle>
              <OdpCaseDataProviders
                image={OdpEarlyData}
                title="Early data shows mixed bill of health for GBR"
                text="Reef condition for Barrier Reef is variable, according to early data released by Australian Institute of Marine Science."
                link="https://www.aims.gov.au/docs/media/"
              />
              <OdpCaseDataProviders
                image={OdpInsideThe}
                title="Inside the effort to map coral reefs from space"
                text="A new ocean mapping tool is giving scientists a way to draw on high-resolution global satellite imagery captured each day"
                link="https://allencoralatlas.org/blog/experimental/"
              />
              <OdpSoundscape
                style={{
                  width: '100%',
                  height: 'auto',
                  marginBottom: '0.5rem',
                }}
              />
              <OdpCaseSidebarTitle>Related Reports</OdpCaseSidebarTitle>
              <OdpCaseRelatedReports />
              <OdpCaseSidebarTitle>Community</OdpCaseSidebarTitle>
              <OdpCaseCommunity />
              <OdpCaseSidebarTitle>Related</OdpCaseSidebarTitle>
              <OdpCaseRelated />
            </OdpCaseSidebarContent>
          )}
        </OdpCaseSidebar>

        {/* Map
          ---------------------------------------------------------------------------- */}
        <OdpGlobalViewer
          full={false}
          height="calc(100vh - 202px)"
          width="100vw"
          position="relative"
        >
          {selectedProviders.some(showProvidersList) ? (
            <OdpDataProvidersActiveList selectedProviders={selectedProviders} />
          ) : null}
          <OdpTerms />
          <OdpZoom />
          <OdpmapPreview />
          <Globe enableLighting={true} />
          <CameraFlyTo destination={positionCameraFlyTo} duration={0} />
          {checkedBenthic ? (
            <ImageryLayer
              imageryProvider={
                new SingleTileImageryProvider({
                  url: OdpBenthic,
                  rectangle: Rectangle.fromDegrees(142.1, -18.7, 152.05, -13.2),
                })
              }
            />
          ) : null}
          {checkedBathymetry ? (
            <ImageryLayer
              imageryProvider={
                new SingleTileImageryProvider({
                  url: OdpBathymerty,
                  rectangle: Rectangle.fromDegrees(141.9, -18.8, 152.6, -13.2),
                })
              }
            />
          ) : null}
        </OdpGlobalViewer>
      </div>
    </>
  )
}

export default hot(GreatBarrierReef)
