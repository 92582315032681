import React from 'react'
import styled from 'styled-components/macro'

// Tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'
import * as colorBrandGreen from '../../assets/styles/tokens/token-color-brand-green.js'

const OdpCheckboxContainer = styled.div`
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
`

const OdpIcon = styled.svg`
  position: relative;
  top: -2px;
  left: -1px;
  fill: none;
  stroke: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
  stroke-width: 3px;
`

const OdpHiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  white-space: nowrap;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
`

const OdpStyledCheckbox = styled.div`
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: 2px;
  border-radius: 2px;
  border: 2px solid
    ${props =>
      props.checked
        ? colorBrandGreen.COLOR_BRAND_GREEN_STATIC
        : colorNeutral.COLOR_NEUTRAL_TINT_50};
  background: ${props =>
    props.checked
      ? colorBrandGreen.COLOR_BRAND_GREEN_STATIC
      : colorNeutral.COLOR_NEUTRAL_TINT_100};
  transition: all 300ms ease-in-out;

  ${OdpIcon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`

const OdpCheckbox = ({ checked, ...props }) => {
  return (
    <OdpCheckboxContainer>
      <OdpHiddenCheckbox checked={checked} {...props} disabled />
      <OdpStyledCheckbox checked={checked}>
        <OdpIcon viewBox="0 0 20 20">
          <polyline points="20 6 9 17 4 12" />
        </OdpIcon>
      </OdpStyledCheckbox>
    </OdpCheckboxContainer>
  )
}

export default OdpCheckbox
