import React from 'react'
import PropTypes from 'prop-types'

class Delayed extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hidden: true }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ hidden: false })
    }, this.props.waitBeforeShow)

    setTimeout(() => {
      this.setState({ hidden: true })
    }, this.props.waitBeforeHide)
  }

  render() {
    return this.state.hidden ? '' : this.props.children
  }
}

Delayed.propTypes = {
  waitBeforeShow: PropTypes.number,
  waitBeforeHide: PropTypes.number,
}

export default Delayed
