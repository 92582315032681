import React from 'react'
import PropTypes from 'prop-types'

// React version fo Cesium
import {
  Entity,
  BillboardGraphics,
  EntityDescription,
  LabelCollection,
  Label,
} from 'resium'

// Cesium
import { Cartesian2, Cartesian3, Transforms, NearFarScalar } from 'cesium'

// Images
import mapMarkerLarge from '../../assets/images/map-marker-large.png'

// Map markers
import mapMarkersData from '../../assets/data/mapMarkers/mapMarkers.array'

// Styling
const font = 'Montserrat'

const OdpGlobalMapMarker = props => {
  const { label } = props

  return (
    <>
      {mapMarkersData.map((context, index) => {
        return (
          <Entity
            key={index}
            name={context.labelTitle}
            position={Cartesian3.fromDegrees(context.long, context.lat, 100)}
          >
            <LabelCollection
              modelMatrix={Transforms.eastNorthUpToFixedFrame(
                Cartesian3.fromDegrees(context.long, context.lat)
              )}
            >
              {label ? (
                <>
                  <Label
                    text={context.labelTitle}
                    font={font}
                    scale={1}
                    pixelOffset={new Cartesian2(30, 8)}
                  />
                  <Label
                    text={context.labelDescription}
                    font={font}
                    scale={0.75}
                    pixelOffset={new Cartesian2(32, 48)}
                    translucencyByDistance={
                      new NearFarScalar(7.0e6, 1.0, 8.0e6, 0.0)
                    }
                  />{' '}
                </>
              ) : null}
            </LabelCollection>
            <BillboardGraphics image={mapMarkerLarge} scale={0.5} />
            <EntityDescription>{context.entityDescription}</EntityDescription>
          </Entity>
        )
      })}
    </>
  )
}

OdpGlobalMapMarker.propTypes = {
  label: PropTypes.bool,
}

export default OdpGlobalMapMarker
