import React from 'react'
import { ImageryLayer } from 'resium'
import { SingleTileImageryProvider, Rectangle } from 'cesium'

// Assets
import SinmodImg from '../../assets/data/sinmod/datalayer.png'

const SinmodOverlay = () => {
  return (
    <>
      <ImageryLayer
        imageryProvider={
          new SingleTileImageryProvider({
            url: SinmodImg,
            rectangle: Rectangle.fromDegrees(
              -179.1473708916438,
              60.17076110839844,
              179.867088317871094,
              89.90359497070312
            ),
          })
        }
      />
    </>
  )
}

export default SinmodOverlay
