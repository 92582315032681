import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { keyFrameLoadBar, keyFrameFadeOut } from '../KeyFrames/OdpKeyframe'

// Import tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral.js'
import * as breakpoint from '../../assets/styles/tokens/token-breakpoints.js'
import * as colorBrandGreen from '../../assets/styles/tokens/token-color-brand-green.js'
import * as fontSize from '../../assets/styles/tokens/token-font-size.js'

const PreloaderContent = styled.div`
  width: calc(100% - 1rem);
  text-align: center;
  animation: ${keyFrameFadeOut} 0.3s ease-in-out 6s forwards;
  position: absolute;
  left: 50%;
  top: calc(50% + 26px);
  transform: translate(-50%, -50%);

  @media (min-width: ${breakpoint.BREAKPOINT_2}) {
    width: auto;
  }
`

const PreloaderText = styled.p`
  color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
  font-size: ${fontSize.FONT_SIZE_2};
`

const PreloadBar = styled.div`
  max-width: 400px;
  height: 8px;
  margin: 0 auto;
  position: relative;
  border-radius: 9999px;
  background-color: ${colorNeutral.COLOR_NEUTRAL_TINT_70};

  ::before {
    width: 0%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    border-radius: 9999px;
    background-color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
    animation: ${keyFrameLoadBar} 6s ease-in-out 0s forwards;
  }
`

const OdpPreloader = ({ children }) => {
  return (
    <PreloaderContent>
      <PreloaderText>{children}</PreloaderText>
      <PreloadBar></PreloadBar>
    </PreloaderContent>
  )
}

PreloaderText.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OdpPreloader
