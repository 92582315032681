import coralIcon from '../../images/buoys-icons/coral-icon.png'
import diversIcon from '../../images/buoys-icons/divers-icon.png'
import fishIcon from '../../images/buoys-icons/fish-icon.png'
import reserveIcon from '../../images/buoys-icons/reserve-icon.png'
import temperatureIcon from '../../images/buoys-icons/temperature-icon.png'
import touristIcon from '../../images/buoys-icons/tourist-icon.png'
import bouysIcon from '../../images/buoys-icons/bouys-icon.png'

// Import images
import OdpWaterTemp from '../../images/graph-watertemperature.png'
import OdpCoralTrend from '../../images/graph-coraltrend.png'

export default [
  {
    labelTitle: 'Opal Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 145.9065083,
    lat: -16.20339163,
    image: bouysIcon,
  },
  {
    labelTitle: 'More Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 146.23358,
    lat: -16.85191004,
    image: bouysIcon,
  },
  {
    labelTitle: 'Feather Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 146.3886667,
    lat: -17.51901666,
    image: bouysIcon,
  },
  {
    labelTitle: 'Roxburgh Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 147.0593033,
    lat: -18.42832658,
    image: bouysIcon,
  },
  {
    labelTitle: 'Knife Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 147.57719,
    lat: -18.57107165,
    image: bouysIcon,
  },
  {
    labelTitle: 'Hardy Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 149.182583,
    lat: -19.745928,
    image: bouysIcon,
  },
  {
    labelTitle: 'Hyde Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 150.08219,
    lat: -19.74567,
    image: bouysIcon,
  },
  {
    labelTitle: 'Globe Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 150.4895,
    lat: -20.76469,
    image: bouysIcon,
  },
  {
    labelTitle: '21-062 Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 150.85337,
    lat: -21.02797,
    image: bouysIcon,
  },
  {
    labelTitle: 'Elusive Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 152.7647,
    lat: -21.1036,
    image: bouysIcon,
  },
  {
    labelTitle: 'Rebe Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 150.14986,
    lat: -19.79497,
    image: bouysIcon,
  },
  {
    labelTitle: 'Orpheus Island',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 146.48955,
    lat: -18.55998,
    image: bouysIcon,
  },
  {
    labelTitle: 'Low Isles',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 145.561921,
    lat: -16.38308303,
    image: bouysIcon,
  },
  {
    labelTitle: 'North Direction Island',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 145.5135766,
    lat: -14.74346166,
    image: bouysIcon,
  },
  {
    labelTitle: 'Sand Bank No7',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 143.97217,
    lat: -13.43628,
    image: bouysIcon,
  },
  {
    labelTitle: 'Osprey Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 146.55567,
    lat: -13.89567,
    image: bouysIcon,
  },
  {
    labelTitle: 'Yonge Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpWaterTemp,
    long: 145.61467,
    lat: -14.57308,
    image: bouysIcon,
  },
  {
    labelTitle: 'Michaelmas Reef',
    labelDescription: ``,
    entityDescription: '',
    long: 146.062111,
    lat: -16.583507,
    image: reserveIcon,
  },
  {
    labelTitle: 'Kewarra Beach',
    labelDescription: ``,
    entityDescription: '',
    long: 145.686054,
    lat: -16.777798,
    image: fishIcon,
  },
  {
    labelTitle: 'Cow Bay',
    labelDescription: ``,
    entityDescription: '',
    long: 145.514087,
    lat: -16.22346,
    image: diversIcon,
  },
  {
    labelTitle: 'Forrester Reef',
    labelDescription: ``,
    entityDescription: '',
    long: 145.476834,
    lat: -15.175007,
    image: diversIcon,
  },
  {
    labelTitle: 'Rocky Ledges',
    labelDescription: ``,
    entityDescription: '',
    long: 144.962067,
    lat: -14.679992,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Evening Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 145.642327,
    lat: -15.907559,
    image: coralIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 147.735227,
    lat: -15.52813,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Hinchinbrook',
    labelDescription: ``,
    entityDescription: '',
    long: 146.299935,
    lat: -18.307042,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Britomart Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 146.698848,
    lat: -18.240545,
    image: coralIcon,
  },
  {
    labelTitle: 'Stanley Island',
    labelDescription: ``,
    entityDescription: '',
    long: 144.208856,
    lat: -14.146962,
    image: touristIcon,
  },
  {
    labelTitle: 'Lytton Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 143.891361,
    lat: -13.740597,
    image: coralIcon,
  },

  // Random markers
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 147.939109,
    lat: -17.429931,
    image: reserveIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 146.776747,
    lat: -15.098924,
    image: fishIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 148.903325,
    lat: -17.080285,
    image: fishIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 147.599095,
    lat: -16.442134,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Little Broadhurst Reef',
    labelDescription: ``,
    entityDescription: '',
    long: 147.700282,
    lat: -18.970975,
    image: diversIcon,
  },
  {
    labelTitle: 'Judith Wright Reef',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 147.953135,
    lat: -18.896841,
    image: coralIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 151.295622,
    lat: -18.537603,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 143.930799,
    lat: -14.324651,
    image: diversIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 144.843721,
    lat: -14.192569,
    image: coralIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 145.132694,
    lat: -14.310578,
    image: touristIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 145.260616,
    lat: -14.383858,
    image: fishIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 146.39405,
    lat: -14.729657,
    image: fishIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 145.600885,
    lat: -15.322292,
    image: coralIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 145.781373,
    lat: -15.91425,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 146.613087,
    lat: -15.376571,
    image: fishIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 146.129822,
    lat: -15.016229,
    image: diversIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 149.714056,
    lat: -14.881249,
    image: fishIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 150.222029,
    lat: -16.92509,
    image: fishIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 150.84108,
    lat: -17.11132,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 151.209593,
    lat: -15.092527,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 146.515412,
    lat: -16.633108,
    image: touristIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 146.171094,
    lat: -16.967484,
    image: coralIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 146.397248,
    lat: -17.339427,
    image: coralIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 146.153009,
    lat: -18.08324,
    image: diversIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 146.661217,
    lat: -17.9974,
    image: touristIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 148.961914,
    lat: -17.826874,
    image: coralIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 148.942402,
    lat: -17.919547,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 143.823743,
    lat: -14.126809,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 146.113377,
    lat: -17.506512,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 145.74069,
    lat: -14.763908,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    long: 144.513203,
    lat: -14.003748,
    image: temperatureIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 145.920748,
    lat: -14.947247,
    image: coralIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 146.634361,
    lat: -14.223959,
    image: coralIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 147.030157,
    lat: -16.755418,
    image: coralIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 147.504712,
    lat: -18.18185,
    image: coralIcon,
  },
  {
    labelTitle: 'Costal Waters',
    labelDescription: ``,
    entityDescription: '',
    entityImage: OdpCoralTrend,
    long: 146.231857,
    lat: -15.9785,
    image: coralIcon,
  },
]
