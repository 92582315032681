import React from 'react'
import { ImageryLayer } from 'resium'
import { SingleTileImageryProvider, Rectangle } from 'cesium'

// Assets
import thfImg from '../../assets/data/trondheimsfjord/datalayer.png'

const SinmodOverlay = () => {
  return (
    <>
      <ImageryLayer
        imageryProvider={
          new SingleTileImageryProvider({
            url: thfImg,
            rectangle: Rectangle.fromDegrees(
              9.601844787597656,
              63.12527084350586,
              11.137504577636719,
              63.82252883911133
            ),
          })
        }
      />
    </>
  )
}

export default SinmodOverlay
