import React from 'react'
import styled from 'styled-components/macro'

// Tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'
import * as borderRadius from '../../assets/styles/tokens/token-border-radius'
import * as zIndex from '../../assets/styles/tokens/token-z-index'

// Import images
import { ReactComponent as OdpDoc } from '../../assets/images/doc.svg'

const OdpTermsContainer = styled.div`
  content: '';
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  z-index: ${zIndex.Z_INDEX_3};
`

const OdpTermsContainerButton = styled.button`
  padding: 0.5rem 0.75rem;
  color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
  display: flex;
  align-items: center;
  border: none;
  border-radius: ${borderRadius.BORDER_RADIUS_6};
  background-color: rgba(0, 0, 0, 0.7);

  svg {
    width: 16px;
    height: 16px;
    fill: white;
  }
`

const OdpTermsContainerButtonText = styled.div`
  margin-left: 0.5rem;
`

const OdpTerms = () => {
  return (
    <OdpTermsContainer>
      <OdpTermsContainerButton>
        <OdpDoc />
        <OdpTermsContainerButtonText>
          Terms, Privacy & More
        </OdpTermsContainerButtonText>
      </OdpTermsContainerButton>
    </OdpTermsContainer>
  )
}

export default OdpTerms
