import React from 'react'
import styled from 'styled-components/macro'

// Tokens
import * as borderRadius from '../../assets/styles/tokens/token-border-radius'
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'
import * as colorBrandGreen from '../../assets/styles/tokens/token-color-brand-green.js'
import * as fontWeight from '../../assets/styles/tokens/token-font-weight'

// Styles
const OdpCaseDropdownListLabel = styled.label`
  transition: color 300ms ease-in-out;

  :hover,
  :focus {
    color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
  }
`

const OdpCaseDropdownListCheckbox = styled.input`
  margin-right: 0.5rem;
  position: relative;
  visibility: hidden;

  ::before {
    width: 12px;
    height: 12px;
    content: '';
    top: 0px;
    left: 0px;
    position: absolute;
    border-radius: ${borderRadius.BORDER_RADIUS_6};
    background-color: ${props =>
      props.checked
        ? colorBrandGreen.COLOR_BRAND_GREEN_STATIC
        : colorNeutral.COLOR_NEUTRAL_TINT_75};
    visibility: visible;
  }

  ::after {
    width: 0.25rem;
    height: 0.25rem;
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: ${borderRadius.BORDER_RADIUS_6};
    background-color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
    visibility: visible;
  }

    + span {
      font-weight: ${props =>
        props.checked ? fontWeight.FONT_WEIGHT_5 : '400'};
    }

  }
`

const OdpProviderCheckbox = props => {
  const { label, checked, handleProviderChange } = props

  return (
    <OdpCaseDropdownListLabel>
      <OdpCaseDropdownListCheckbox
        type="checkbox"
        checked={checked}
        value={label}
        onChange={handleProviderChange}
      />
      <span>{label}</span>
    </OdpCaseDropdownListLabel>
  )
}

export default OdpProviderCheckbox
