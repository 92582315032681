import React from 'react'
import PropTypes from 'prop-types'

// Resium
import { Viewer, Globe } from 'resium'

// Cesium
// import { IonImageryProvider } from 'cesium'

const OdpGlobalViewer = props => {
  const { full, width, height, position, children } = props

  return (
    <>
      <Viewer
        full={full}
        style={{ width: width, height: height, position: position }}
        animation={false} // Hide animate timeline options
        baseLayerPicker={false} // Hide terrain option
        fullscreenButton={false} // Hide fullscreen option
        geocoder={false} // Hide search option
        homeButton={false} // Hide home button option
        navigationHelpButton={false} // Hide help button
        sceneModePicker={false} // Hide globe, 2D and 3D options
        timeline={false} // Hide timeline
        // imageryProvider={new IonImageryProvider({ assetId: 3954 })} // Change from Bing Map to Sentinel-2. So that we don't exceed the max Bing quota
      >
        <Globe enableLighting={true} />
        {children}
      </Viewer>
    </>
  )
}

OdpGlobalViewer.propTypes = {
  full: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  position: PropTypes.string,
}

export default OdpGlobalViewer
