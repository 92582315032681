import React, { useRef } from 'react'
import styled from 'styled-components/macro'

// Resium
import { CameraFlyTo, KmlDataSource } from 'resium'
import { Cartesian3 } from 'cesium'

// Import components
import OdpHeader from '../../components/Header/OdpHeader'
import OdpCarousel from '../../components/Carousel/OdpCarousel'
import OdpCardsList from '../../components/CardsList/OdpCardsList'
import OdpFooter from '../../components/Footer/OdpFooter'
import OdpSearch from '../../components/Search/OdpSearch'
import OdpDelayed from '../../components/Delayed/OdpDelayed'

import { keyFrameFadeIn } from '../../components/KeyFrames/OdpKeyframe'

// Custom Cesium components
import OdpGlobalViewer from '../../components/GlobalViewer/OdpGlobalViewer'
import OdpGlobalMapMarker from '../../components/GlobalMapMarker/OdpGlobalMapMarker'
import SinmodOverlay from '../../components/ImageryOverlays/SinmodOverlay'
import TrondheimOverlay from '../../components/ImageryOverlays/TrondheimOverlay'

// Import content
import { LATEST, FEATURED } from '../../assets/data/explore/exploreContent'
import worldTwentyFive from '../../assets/data/home/mini-sample-world-25.kml'

// Import design tokens
import * as colorBrandGreen from '../../assets/styles/tokens/token-color-brand-green.js'
import * as zIndex from '../../assets/styles/tokens/token-z-index'
import { FOOTER_HEIGHT } from '../../assets/styles/tokens/token-layout'
// Styling
const PageContainer = styled.div`
  position: relative;
`

const ContentWrap = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 3rem 0;
  padding-bottom: calc(${FOOTER_HEIGHT}rem + 6rem);
  text-align: center;

  span {
    padding-top: 1rem;
  }
`

const CallToAction = styled.a`
  width: 2rem;
  margin-left: -1rem;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 16px;
  left: 50%;
  opacity: 0;
  z-index: ${zIndex.Z_INDEX_2};
  animation: ${keyFrameFadeIn} 0.3s ease-in-out 0s forwards;
`

const CallToActionContent = styled.div`
  width: 3px;
  height: 48px;
  background-color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};

  ::before,
  ::after {
    width: 16px;
    height: 3px;
    content: '';
    position: absolute;
    bottom: 5px;
    background-color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
  }

  ::before {
    left: 3px;
    transform: rotate(45deg);
  }

  ::after {
    right: 2px;
    transform: rotate(-45deg);
  }
`

const Explore = () => {
  const contentRef = useRef(null)

  const scrollToRef = ref =>
    window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' })

  return (
    <PageContainer>
      <OdpHeader />
      <OdpGlobalViewer full={false} height="80vh" position="relative">
        <SinmodOverlay />
        <TrondheimOverlay />
        <CameraFlyTo
          destination={
            new Cartesian3.fromDegrees(139.767052, 35.681167, 25000000)
          }
          duration={0}
        />
        <KmlDataSource data={worldTwentyFive} />
        <OdpGlobalMapMarker label={true} />
        <OdpDelayed waitBeforeShow={6000}>
          <CallToAction onClick={() => scrollToRef(contentRef)}>
            <CallToActionContent />
          </CallToAction>
        </OdpDelayed>
      </OdpGlobalViewer>
      <ContentWrap ref={contentRef}>
        <OdpSearch
          placeholder="Search data e.g. Great Barrier Reef"
          iconSize="2rem"
          style={{
            width: '428px',
            margin: '0 auto 3rem auto',
            fontSize: '1.250rem',
          }}
        />
        <OdpCarousel />
        <OdpCardsList cards={LATEST} listTitle="LATEST" />
        <OdpCardsList cards={FEATURED} listTitle="FEATURED" />
      </ContentWrap>
      <OdpFooter />
    </PageContainer>
  )
}

export default Explore
