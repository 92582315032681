import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import { keyFrameFadeIn } from '../KeyFrames/OdpKeyframe'

// Import tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral.js'
import * as colorBrandGreen from '../../assets/styles/tokens/token-color-brand-green.js'
import * as breakpoint from '../../assets/styles/tokens/token-breakpoints.js'
import * as borderRadius from '../../assets/styles/tokens/token-border-radius'

export default styled(Link)`
  width: 15rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: center;
  color: ${colorNeutral.COLOR_NEUTRAL_TINT_15};
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: calc(50% + 35px);
  transform: translate(-50%, -50%);
  border-radius: ${borderRadius.BORDER_RADIUS_6};
  background-color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
  transition: all 300ms ease;
  animation: ${keyFrameFadeIn} 0.3s ease-in-out 0s forwards;

  :hover,
  :focus {
    color: ${colorNeutral.COLOR_NEUTRAL_TINT_15};
    background-color: ${colorBrandGreen.COLOR_BRAND_GREEN_LIGHT};
    transform: translate(-50%, -50%) scale(1.1, 1.1);
  }

  @media (min-width: ${breakpoint.BREAKPOINT_2}) {
    width: auto;
    padding-right: 2rem;
    padding-left: 2rem;
    font-size: 1.125rem;
  }
`
