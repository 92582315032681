module.exports = [
  {
    label: 'Australian Institute of Marine Science - AIMS',
  },
  {
    label: 'Allen Coral Atlas',
  },
  {
    label: 'BarentsWatch',
  },
  {
    label: 'eAtlas',
  },
  {
    label: 'FishBase',
  },
  {
    label: 'Global Fishing Watch',
  },
  {
    label: 'Institute of Marine Research',
  },
  {
    label: 'IUCN',
  },
  {
    label: 'Mermaid',
  },
  {
    label: 'MPAtlas',
  },
  {
    label: 'Norwegian Environment Agency',
  },
  {
    label: 'NOAA',
  },
  {
    label: 'Ocean Agency',
  },
  {
    label: 'ReefCheck Australia',
  },
  {
    label: 'Systema Naturae',
  },
]
