import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

// Cesium
import Cesium from 'cesium'

// Custom styling
import './assets/styles/css/cesium.css'

// Import Google fonts as NPM
// ----------------------------------------------------------------------------
require('typeface-lato')
require('typeface-cormorant-garamond')
require('typeface-montserrat')

// Cesium access token
// NOTE: registered on knut.sorknes@gmail.com and not REV Ocean
Cesium.Ion.defaultAccessToken =
  // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4OTkwZWQyNC1jYTQwLTRiNDMtYjU4Ny0yNjA3MzI0YTA1ZDUiLCJpZCI6MTU3MjcsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1Njg3MDk0MjJ9.dmKZE2xMRIiie-_PCJGUWvIFNNEO4IBbBelXI6Wjpb4'
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzOTkxNzI0Yy01NWIwLTQ2ZGUtYTlkOS04NmVlYjJjYmZhMWIiLCJpZCI6MTY4MDEsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NzEyMDc0NTB9.RtJ0TJRF6NBBuRKVwepB37zmthC_hT163NcNaDXtVhE'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
