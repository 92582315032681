import { keyframes } from 'styled-components/macro'

export const keyFrameLoadBar = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`

export const keyFrameFadeIn = keyframes`
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 1;
  }
`

export const keyFrameFadeOut = keyframes`
  0% {
    opacity: 1%;
  }
  100% {
    opacity: 0;
  }
`

export const keyFrameFloat = keyframes`
  0% {
    bottom: 32px;
  }
  50% {
    bottom: 16px;
  }
  100% {
    bottom: 32px;
  }
`
