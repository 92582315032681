import React from 'react'
import styled from 'styled-components/macro'

// Import images
import { ReactComponent as OdpNewsIcon } from '../../assets/images/sidebar-icons/news-icon.svg'
import { ReactComponent as OdpGalleryIcon } from '../../assets/images/sidebar-icons/gallery-icon.svg'
import { ReactComponent as OdpPodcastIcon } from '../../assets/images/sidebar-icons/podcast-icon.svg'
import { ReactComponent as OdpVideoIcon } from '../../assets/images/sidebar-icons/video-icon.svg'

const CaseRelatedList = styled.ul`
  padding: 0;
  margin: 0 0 1rem 0;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
`

const CaseRelatedListItem = styled.li`
  width: calc(25% - 0.5rem);
  text-align: center;

  :not(:last-child) {
    margin-right: 0.5rem;
  }
`

const CaseRelatedFigure = styled.figure`
  width: 100%;
  height: 48px;
  margin: 0;

  svg {
    width: 100%;
    height: auto;
  }

  img {
    width: 100%;
    height: 48px;
  }
`

const CaseRelatedText = styled.p`
  margin: 0;
`

const CaseRelated = () => {
  return (
    <CaseRelatedList>
      <CaseRelatedListItem>
        <CaseRelatedFigure>
          <OdpNewsIcon style={{ width: '32px' }} />
        </CaseRelatedFigure>
        <CaseRelatedText>News</CaseRelatedText>
      </CaseRelatedListItem>
      <CaseRelatedListItem>
        <CaseRelatedFigure>
          <OdpGalleryIcon style={{ width: '32px' }} />
        </CaseRelatedFigure>
        <CaseRelatedText>Gallery</CaseRelatedText>
      </CaseRelatedListItem>
      <CaseRelatedListItem>
        <CaseRelatedFigure>
          <OdpPodcastIcon style={{ width: '32px' }} />
        </CaseRelatedFigure>
        <CaseRelatedText>Podcasts</CaseRelatedText>
      </CaseRelatedListItem>
      <CaseRelatedListItem>
        <CaseRelatedFigure>
          <OdpVideoIcon style={{ width: '32px' }} />
        </CaseRelatedFigure>
        <CaseRelatedText>Videos</CaseRelatedText>
      </CaseRelatedListItem>
    </CaseRelatedList>
  )
}

export default CaseRelated
