import React from 'react'
import styled from 'styled-components/macro'

// Tokens
import * as fontWeight from '../../assets/styles/tokens/token-font-weight'

const OdpCaseSidebarContent = styled.h2`
  width: 100%;
  font-size: 1rem;
  font-weight: ${fontWeight.FONT_WEIGHT_5};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OdpCaseSidebarTitle = props => {
  const { children } = props

  return <OdpCaseSidebarContent>{children}</OdpCaseSidebarContent>
}

export default OdpCaseSidebarTitle
