import React from 'react'
import styled from 'styled-components/macro'
import { Icon } from 'antd'

// Tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'
import * as borderRadius from '../../assets/styles/tokens/token-border-radius'
import * as zIndex from '../../assets/styles/tokens/token-z-index'

const Container = styled.div`
  content: '';
  position: absolute;
  bottom: 7rem;
  left: 2rem;
  z-index: ${zIndex.Z_INDEX_3};
`

const Title = styled.p`
  margin: 0;
  color: #fff;
`

const Pill = styled.div`
  margin: 0.25rem 0;
  padding: 0.25rem 0.5rem;
  width: max-content;
  font-size: 10px;
  color: ${colorNeutral.COLOR_NEUTRAL_TINT_0};
  align-items: center;
  border: none;
  border-radius: ${borderRadius.BORDER_RADIUS_6};
  background-color: rgba(255, 255, 255, 0.8);
`

const OdpDataProvidersActiveList = ({ selectedProviders }) => {
  return (
    <Container>
      <Title>
        Data Providers <Icon type="caret-down" />
      </Title>
      {selectedProviders.map(selected =>
        selected.checked ? (
          <Pill key={selected.label}>{selected.label}</Pill>
        ) : null
      )}
    </Container>
  )
}

export default OdpDataProvidersActiveList
