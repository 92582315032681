import React from 'react'
import { hot } from 'react-hot-loader/root'

// Resium
import { CameraFlyTo, KmlDataSource } from 'resium'
import { Cartesian3 } from 'cesium'

// Custom components
import OdpHeader from '../../components/Header/OdpHeader'
import OdpOverlayFlex from '../../components/OverlayFlex/OdpOverlayFlex'
import OdpJumbotron from '../../components/Jumbotron/OdpJumbotron'
import LinkButton from '../../components/JumbotronLinkButton/OdpJumbotronLinkButton'

// Preloader concept
import OdpDelayed from '../../components/Delayed/OdpDelayed'
import OdpPreloader from '../../components/Preloader/OdpPreloader'
import OdpBox from '../../components/Box/OdpBox'

// Custom Cesium components
import OdpGlobalViewer from '../../components/GlobalViewer/OdpGlobalViewer'
import OdpGlobalMapMarker from '../../components/GlobalMapMarker/OdpGlobalMapMarker'
import SinmodOverlay from '../../components/ImageryOverlays/SinmodOverlay'

import twentyFivePercent from '../../assets/data/home/mini-sample-world-25.kml'

const Home = () => {
  return (
    <>
      <OdpHeader />
      <OdpOverlayFlex>
        <OdpJumbotron>
          Liberating data for
          <br /> one healthy ocean
        </OdpJumbotron>
        <OdpDelayed waitBeforeHide={6000}>
          <OdpPreloader>
            Liberating over 1,000,314 data points from across the oceans ...
          </OdpPreloader>
        </OdpDelayed>
        <OdpDelayed waitBeforeShow={6000}>
          <LinkButton to="/explore">Explore Ocean Data</LinkButton>
        </OdpDelayed>
        <OdpDelayed waitBeforeShow={6000}>
          <OdpBox title="LIVE DATA">
            Impact of reduced emission from domestic and international marine
            transport and shipping
          </OdpBox>
        </OdpDelayed>
      </OdpOverlayFlex>
      <OdpGlobalViewer full={true} position="absolute">
        <CameraFlyTo
          destination={
            new Cartesian3.fromDegrees(139.767052, 35.681167, 10000000)
          }
          duration={6}
        />
        <SinmodOverlay />
        <OdpDelayed waitBeforeShow={5500}>
          <KmlDataSource data={twentyFivePercent} />
        </OdpDelayed>
        <OdpGlobalMapMarker label={false} />
      </OdpGlobalViewer>
    </>
  )
}

export default hot(Home)
