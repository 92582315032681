import React, { Component } from 'react'
import { hot } from 'react-hot-loader/root'

// React version fo Cesium
import { CameraFlyTo } from 'resium'

// Cesium
import { Cartesian3 } from 'cesium'

// Custom Cesium components
import OdpGlobalViewer from '../../components/GlobalViewer/OdpGlobalViewer'
import OdpGlobalMapMarker from '../../components/GlobalMapMarker/OdpGlobalMapMarker'

class PageThreeTest extends Component {
  render() {
    return (
      <OdpGlobalViewer>
        <CameraFlyTo
          destination={
            new Cartesian3.fromDegrees(139.767052, 35.681167, 10000000)
          }
          duration={0}
        />
        <OdpGlobalMapMarker />
      </OdpGlobalViewer>
    )
  }
}

export default hot(PageThreeTest)
