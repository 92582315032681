module.exports = [
  {
    labelTitle: 'Offshore wind ',
    labelDescription: `The Hywind Tampen offshore wind farm will consist of 11\nturbines with a total capacity of 88 MW.`,
    entityDescription: '',
    long: 1.9,
    lat: 61.166667,
  },
  {
    labelTitle: 'Ocean-based transport ',
    labelDescription: `The port of Rotterdam has a yearly throughput of 240.7\nmillion tones. A total of 8.6 million containers.`,
    long: 4.0,
    lat: 52.033333,
  },
  {
    labelTitle: 'Coastal and marine ecosystems',
    labelDescription: `The Great Barrier Reef is the world's largest coral reef system composed\nof over 2,900 individual reefs stretching for over 2,300 kilometers.`,
    long: 147.716667,
    lat: 18.033333,
  },
  {
    labelTitle: 'Fisheries and aquaculture',
    labelDescription: `Chile is the second largest salmon and trout producer in the\nworld and contributes to 38% of the world’s salmon volume.`,
    long: 71.016667,
    lat: -35.25,
  },
  {
    labelTitle: 'Carbon storage in seabed',
    labelDescription: `The floor of the Gulf of Mexico could\nbe future home to a lot of carbon`,
    long: -86.916667,
    lat: 25.45,
  },
  {
    labelTitle: 'Marine pollution',
    labelDescription: `The Great Pacific Garbage Patch contains a staggering number\nof pieces of plastic, estimated to be between 1.1 to 3.6 trillion. `,
    long: 42.35,
    lat: -133.916667,
  },
  {
    labelTitle: 'Ocean acidification',
    labelDescription: `Over the last 250 years, the oceans have absorbed 560 billion tons of CO2, increasing the acidity\nof surface waters by 30%. The current observed rate of change is roughly 50 times faster than known historical change. `,
    long: -76.55,
    lat: 15.65,
  },
  {
    labelTitle: 'Protect marine areas',
    labelDescription: `Only around 1 percent of the world’s oceans are closed to fishing, even though fishing restrictions inside MPAs\nactually benefit fisheries in the longer run by providing areas where fish are able to spawn and safely grow to their adult size. `,
    long: 19.283333,
    lat: -35.05,
  },
  {
    labelTitle: 'Seabed mining',
    labelDescription: `Deep sea mining is a relatively new field and the complete consequences\nof full-scale mining operations on this ecosystem are unknown.`,
    long: 152.533333,
    lat: -4.65,
  },
  {
    labelTitle: 'Blue carbon',
    labelDescription: `Mangroves, salt marshes, and seagrass beds are highly productive vegetated\ncoastal ecosystems, which are referred to as “blue carbon” ecosystems.`,
    long: -38.7,
    lat: -13.25,
  },
  {
    labelTitle: 'Floating solar plant',
    labelDescription: `The O’MEGA1 project is a 17 megawatt (MW)\nsolar plant situated in Piolenc in France.`,
    long: 4.76408,
    lat: 44.17945,
  },

  // Extra dotts
  // {
  //   labelTitle: 'North Pacific Ocean',
  //   labelDescription: `Label description with some more text. \nSecond line with more text too`,
  //   entityDescription: 'Entity description',
  //   long: 154.201134,
  //   lat: 28.896515,
  // },
  // {
  //   labelTitle: 'South China Sea',
  //   labelDescription: `Label description with some more text. \nSecond line with more text too`,
  //   entityDescription: 'Entity description',
  //   long: 112.98843,
  //   lat: 17.309298,
  // },
  // {
  //   labelTitle: 'North Pacific Ocean',
  //   labelDescription: `Label description with some more text. \nSecond line with more text too`,
  //   entityDescription: 'Entity description',
  //   long: -156.066091,
  //   lat: 50.901214,
  // },
  // {
  //   labelTitle: 'Hawaii',
  //   labelDescription: `Label description with some more text. \nSecond line with more text too`,
  //   entityDescription: 'Entity description',
  //   long: -159.548169,
  //   lat: 21.995975,
  // },
  // {
  //   labelTitle: 'Sea of Okhotsk',
  //   labelDescription: `Label description with some more text. \nSecond line with more text too`,
  //   entityDescription: 'Entity description',
  //   long: 146.779877,
  //   lat: 55.681557,
  // },
  // {
  //   labelTitle: 'Yellow Sea',
  //   labelDescription: `Label description with some more text. \nSecond line with more text too`,
  //   entityDescription: 'Entity description',
  //   long: 122.413879,
  //   lat: 35.372555,
  // },
  // {
  //   labelTitle: 'Philippine Sea',
  //   labelDescription: `Label description with some more text. \nSecond line with more text too`,
  //   entityDescription: 'Entity description',
  //   long: 127.047907,
  //   lat: 13.307312,
  // },
  // {
  //   labelTitle: 'South Pacific',
  //   labelDescription: `Label description with some more text. \nSecond line with more text too`,
  //   entityDescription: 'Entity description',
  //   long: -162.065468,
  //   lat: -7.393891,
  // },
]
