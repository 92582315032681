import React from 'react'
import styled from 'styled-components/macro'

// Tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'

// Images
import OdpOutlook from '../../assets/images/case/img-outlook.png'
import OdpAccess from '../../assets/images/case/img-access.png'
import OdpGbr from '../../assets/images/case/img-gbr.png'

const OdpCaseRelatedReportsContainer = styled.div`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  position: relative;
  border-radius: 4px;
  background-color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
  overflow: hidden;
`

const OdpCaseRelatedReportsList = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  list-style-type: none;
`

const OdpCaseRelatedReportsListItem = styled.li`
  width: calc(100% + 2rem);

  :not(:last-child) {
    margin-right: 2rem;
  }
`

const OdpCaseRelatedReportsFigure = styled.figure`
  width: 100%;
  height: 142px;
  margin-bottom: 0;
  border-radius: 4px;

  img {
    width: 100%;
    height: 142px;
    border-radius: 4px;
  }
`

const OdpCaseRelatedReportsTitle = styled.h3`
  margin: 0;
  display: flex;
  align-items: flex-start;
  font-size: 11px;
`

const OdpCaseRelatedReportsText = styled.p`
  width: 100%;
  margin-bottom: 0;
  font-size: 8px;
  word-wrap: normal;
`

const OdpCaseRelatedReportsGradient = styled.div`
  width: 80px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 85%
  );
`

const OdpCaseRelatedReports = () => {
  return (
    <OdpCaseRelatedReportsContainer>
      <OdpCaseRelatedReportsGradient />
      <OdpCaseRelatedReportsList>
        <OdpCaseRelatedReportsListItem>
          <OdpCaseRelatedReportsFigure>
            <img src={OdpOutlook} alt="alt" />
          </OdpCaseRelatedReportsFigure>
          <OdpCaseRelatedReportsTitle>
            Outlook Report
          </OdpCaseRelatedReportsTitle>
          <OdpCaseRelatedReportsText>
            Australian Government
          </OdpCaseRelatedReportsText>
        </OdpCaseRelatedReportsListItem>
        <OdpCaseRelatedReportsListItem>
          <OdpCaseRelatedReportsFigure>
            <img src={OdpAccess} alt="alt" />
          </OdpCaseRelatedReportsFigure>
          <OdpCaseRelatedReportsTitle>
            Access Economies
          </OdpCaseRelatedReportsTitle>
          <OdpCaseRelatedReportsText>Deloitte</OdpCaseRelatedReportsText>
        </OdpCaseRelatedReportsListItem>
        <OdpCaseRelatedReportsListItem>
          <OdpCaseRelatedReportsFigure>
            <img src={OdpGbr} alt="alt" />
          </OdpCaseRelatedReportsFigure>
          <OdpCaseRelatedReportsTitle>GBR Concerns</OdpCaseRelatedReportsTitle>
          <OdpCaseRelatedReportsText>
            AMCS & WWF (Aus)
          </OdpCaseRelatedReportsText>
        </OdpCaseRelatedReportsListItem>
      </OdpCaseRelatedReportsList>
    </OdpCaseRelatedReportsContainer>
  )
}

OdpCaseRelatedReports.defaultProps = {}

OdpCaseRelatedReports.propTypes = {}

export default OdpCaseRelatedReports
