import React from 'react'
import styled from 'styled-components/macro'

// Tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'
import * as zIndex from '../../assets/styles/tokens/token-z-index'

const OdpZoomContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: ${zIndex.Z_INDEX_2};
`

const OdpZoomContainerControl = styled.div`
  width: 30px;
  height: 30px;
  color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
  font-size: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colorNeutral.COLOR_NEUTRAL_TINT_0};

  :not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

const OdpZoom = () => {
  return (
    <OdpZoomContainer>
      <OdpZoomContainerControl>+</OdpZoomContainerControl>
      <OdpZoomContainerControl>-</OdpZoomContainerControl>
    </OdpZoomContainer>
  )
}

export default OdpZoom
