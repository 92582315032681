import React from 'react'
import styled from 'styled-components/macro'

// Tokens
import * as zIndex from '../../assets/styles/tokens/token-z-index'

// Import images
import { ReactComponent as OdpGbrMap } from '../../assets/images/gbr-map-preview.svg'

const OdpmapPreviewContainer = styled.div`
  width: 124px;
  height: 80px;
  content: '';
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  z-index: ${zIndex.Z_INDEX_2};
`

const OdpmapPreview = () => {
  return (
    <OdpmapPreviewContainer>
      <OdpGbrMap />
    </OdpmapPreviewContainer>
  )
}

export default OdpmapPreview
