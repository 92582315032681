import React from 'react'
import styled from 'styled-components'

// Import Ant Design
import { Carousel, Icon } from 'antd'

// Import design tokens
import { COLOR_BRAND_GREEN_STATIC } from '../../assets/styles/tokens/token-color-brand-green'
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'
import { DURATION_3 } from '../../assets/styles/tokens/token-duration'
import {
  FONT_SIZE_2,
  FONT_SIZE_6,
} from '../../assets/styles/tokens/token-font-size'
import * as borderRadius from '../../assets/styles/tokens/token-border-radius'

// Import assets
import { ReactComponent as AimsLogo } from '../../assets/images/logos/aims.svg'
import { ReactComponent as VulcanLogo } from '../../assets/images/logos/vulcan.svg'
import { ReactComponent as GlobalFishingWatchLogo } from '../../assets/images/logos/global-fish-watch.svg'

// Component config
import carouselImg from '../../assets/images/explore/carousel.jpg'

// Styling
const S = {}
S.Carousel = styled(Carousel)`
  height: 100%;
  width: 100%;

  .ant-carousel {
    height: inherit;
    width: inherit;
  }
`

const Container = styled.div`
  position: relative;

  overflow: hidden;

  border-top-left-radius: ${DURATION_3};
  border-top-right-radius: ${DURATION_3};

  height: 30rem;
  width: 100%;

  ::before {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9) 8%,
        rgba(255, 255, 255, 0) 64%
      ),
      url(${carouselImg});
  }
`

const CarouselItem = styled.div`
  position: relative;

  padding: 2rem;
  padding-left: 4rem;

  height: inherit;
  width: inherit;
`

const ItemContent = styled.div`
  display: flex;
  position: relative;

  justify-content: space-between;

  margin-top: 12rem;

  height: 100%;
  width: 100%;

  color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
  text-align: left;

  p {
    margin-bottom: 0.5rem;
  }
`

const TextWrap = styled.div`
  height: 100%;
  width: 29rem;
`

const Header = styled.h3`
  color: inherit;
  font-size: ${FONT_SIZE_6};
`

const Subhead = styled.p`
  font-size: ${FONT_SIZE_2};
`

const SupportText = styled.p``

const ProvidersWrap = styled.div`
  position: absolute;

  right: 0;
  bottom: -1rem;

  text-align: right;

  ul {
    display: flex;
    position: relative;

    margin: 0;

    flex-direction: column;

    list-style: none;

    ::after {
      content: '';
      position: absolute;

      top: 16px;
      right: -12px;
      bottom: 16px;

      background-color: #fff;

      width: 1px;
    }

    li {
      display: flex;
      position: relative;

      align-self: flex-end;

      margin: 0.25rem 0;
      background-color: ${COLOR_BRAND_GREEN_STATIC};
      padding: 0.25rem 0.75rem;

      border-radius: ${borderRadius.BORDER_RADIUS_6};

      width: max-content;

      color: #000;
      text-align: center;

      span {
        display: flex;

        align-items: center;

        margin-right: 0.5rem;
        padding-top: 0;

        svg {
          height: 18px;
          width: 18px;
        }
      }
    }

    li::before {
      position: absolute;

      top: 7px;
      right: -6px;

      border-radius: 100%;
      background-color: #fff;

      height: 0.75rem;
      width: 0.75rem;

      content: '';
    }
    li::after {
      position: absolute;

      top: 12px;
      right: -12px;

      background-color: #fff;

      height: 1px;
      width: 12px;

      content: '';
    }
  }

  i {
    padding-right: 0.75rem;
  }
`

const CarouselButton = styled.div`
  position: absolute;

  margin-top: -24px;

  border-radius: 100%;

  top: 50%;
  right: 2rem;

  background-color: rgba(255, 255, 255, 0.2);

  height: 3rem;
  width: 3rem;

  color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
  font-size: 32px;
`

const OdpCarousel = () => {
  return (
    <Container>
      <S.Carousel>
        <CarouselItem>
          <CarouselButton>
            <Icon type="right" />
          </CarouselButton>
          <ItemContent>
            <TextWrap>
              <Header>Ocean-based transportation</Header>
              <Subhead>OCEAN INSTITUTE</Subhead>
              <SupportText>
                This interactive report analyses the potential mitigation impact
                of reducing emissions from domestic and international marine
                transport and shipping
              </SupportText>
            </TextWrap>
            <ProvidersWrap>
              <ul>
                <li>
                  <span>
                    <VulcanLogo />
                  </span>
                  Vulcan
                </li>
                <li>
                  <span>
                    <GlobalFishingWatchLogo />
                  </span>
                  Global Fishing Watch
                </li>
                <li>
                  <span>
                    <AimsLogo />
                  </span>
                  Australian Institute of Marine Science (AIMS)
                </li>
              </ul>
              <Icon type="caret-up" />
              <p>Data Providers</p>
            </ProvidersWrap>
          </ItemContent>
        </CarouselItem>
        <div>
          <h3>2</h3>
        </div>
        <div>
          <h3>3</h3>
        </div>
      </S.Carousel>
    </Container>
  )
}

export default OdpCarousel
