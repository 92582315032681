import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// Import tokens
import * as zIndex from '../../assets/styles/tokens/token-z-index.js'

const OverlayFlexContainer = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${zIndex.Z_INDEX_1};
`

const OdpOverlayFlex = ({ children }) => {
  return <OverlayFlexContainer>{children}</OverlayFlexContainer>
}

OverlayFlexContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OdpOverlayFlex
