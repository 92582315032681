import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
// Ant Design
import { Card } from 'antd'
// Colors
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'

const S = {}
S.Card = styled(Card)`
  height: 15rem;

  :hover {
    transform: scale(1.05, 1.05);
  }

  .ant-card-body {
    position: relative;

    padding: 0;

    height: 100%;
    width: 100%;
  }
`

const CardMedia = styled.div`
  position: absolute;

  height: inherit;
  width: inherit;

  ::before {
    position: absolute;

    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 8%,
      rgba(255, 255, 255, 0) 100%
    );

    height: inherit;
    width: inherit;

    content: '';
  }

  img {
    object-fit: cover;

    height: inherit;
    width: inherit;
  }
`

const CardContent = styled.div`
  width: inherit;
  height: inherit;

  color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};

  text-align: left;

  p {
    position: absolute;
    right: 1.5rem;
    left: 1.5rem;
  }
`

const Header = styled.p`
  bottom: 1.875rem;

  font-size: 1.675rem;
  line-height: 2rem;
`
const Subhead = styled.p`
  opacity: 0.7;

  bottom: 1.25rem;

  font-size: 0.875rem;
`

const SupportingText = styled.p`
  opacity: 0.7;

  bottom: 0;

  font-size: 0.875rem;
`

const OdpCard = props => {
  const {
    url,
    imgUrl,
    imgAlt,
    headerText,
    subHeaderText,
    supportingText,
  } = props

  return (
    <Link to={url}>
      <S.Card hoverable bordered={false}>
        <CardMedia>
          <img src={imgUrl} alt={imgAlt} />
        </CardMedia>
        <CardContent>
          <Header>{headerText}</Header>
          <Subhead>{subHeaderText}</Subhead>
          <SupportingText>{supportingText}</SupportingText>
        </CardContent>
      </S.Card>
    </Link>
  )
}

OdpCard.defaultProps = {
  url: '/',
  imgUrl:
    'https://london-nerc-dtp.org/wp-content/uploads/2018/04/EarthAtmosphereOcean-300x206.jpg',
  imgAlt: 'Planet',
  headerText: 'Ocean-based',
  subHeaderText: 'Ocean Institute',
  supportingText: 'Oct 23, 2019',
}

OdpCard.propTypes = {
  url: PropTypes.string,
  imgUrl: PropTypes.string,
  imgAlt: PropTypes.string,
  headerText: PropTypes.string,
  subHeaderText: PropTypes.string,
  supportingText: PropTypes.string,
}

export default OdpCard
