import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

// Assets
import facebook from '../../assets/images/social/facebook.png'
import instagram from '../../assets/images/social/instagram.png'
import linkedin from '../../assets/images/social/linkedin.png'
import twitter from '../../assets/images/social/twitter.png'

// Styling
import { FOOTER_HEIGHT } from '../../assets/styles/tokens/token-layout'
import {
  COLOR_NEUTRAL_TINT_100,
  COLOR_NEUTRAL_TINT_5,
} from '../../assets/styles/tokens/token-color-neutral'
import { COLOR_BRAND_GREEN_LIGHT } from '../../assets/styles/tokens/token-color-brand-green'
import { FONT_WEIGHT_4 } from '../../assets/styles/tokens/token-font-weight'

const Footer = styled.footer`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: ${FOOTER_HEIGHT}rem;
  color: ${COLOR_NEUTRAL_TINT_100};
  background-color: ${COLOR_NEUTRAL_TINT_5};
`
const FooterContainer = styled.div`
  max-width: 1024px;
  padding: 3rem 0;
  margin: 0 auto;
`

const FooterColumns = styled.div`
  display: flex;
  justify-content: space-around;
`

const Col = styled.div`
  width: 33.3333%;

  h3 {
    margin-bottom: 2rem;
    color: inherit;
  }
`

const Item = styled.div`
  margin: 0.75rem 0;

  a {
    color: inherit;
  }

  a:hover {
    color: ${COLOR_BRAND_GREEN_LIGHT};
  }
`

const Legal = styled.div`
  padding: 3rem 0;
  text-align: center;

  span {
    padding: 0 0.5rem;
    opacity: 0.7;
    font-weight: ${FONT_WEIGHT_4};
  }
`

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 3rem;

  width: 100%;
`

const SocialIcon = styled.img`
  height: 50px;
  width: 50px;
`

const OdpFooter = () => {
  return (
    <Footer role="contentinfo">
      <FooterContainer>
        <FooterColumns>
          <Col>
            <h3>About</h3>
            <Item>
              <Link to="/">The Foundation</Link>
            </Item>
            <Item>
              <Link to="/">Initiative</Link>
            </Item>
          </Col>
          <Col>
            <h3>Communities</h3>
            <Item>
              <Link to="/">For Scientists</Link>
            </Item>
            <Item>
              <Link to="/">For Citizen Scientists</Link>
            </Item>
            <Item>
              <Link to="/">For Data Providers</Link>
            </Item>
            <Item>
              <Link to="/">For Industrial Partners</Link>
            </Item>
          </Col>
          <Col>
            <h3>Links</h3>
            <Item>
              <Link to="/">Subscribe to Newsletter</Link>
            </Item>
            <SocialContainer>
              <a
                href="https://www.facebook.com/OceanREV/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src={facebook} alt="facebook icon" srcset="" />
              </a>
              <a
                href="https://www.instagram.com/ocean_data_foundation/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src={instagram} alt="instagram icon" srcset="" />
              </a>
              <a
                href="https://www.linkedin.com/company/oceandatafoundation/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src={linkedin} alt="linkedin icon" srcset="" />
              </a>
              <a
                href="https://twitter.com/rev_ocean"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src={twitter} alt="twitter icon" srcset="" />
              </a>
            </SocialContainer>
          </Col>
        </FooterColumns>
        <Legal>
          <span>Terms & Conditions</span>
          <span>Copyright Policy</span>
          <span>Privacy Policy</span>
          <span>Cookies</span>
        </Legal>
      </FooterContainer>
    </Footer>
  )
}

OdpFooter.propTypes = {
  children: PropTypes.string,
}

export default OdpFooter
