import React from 'react'
import { hot } from 'react-hot-loader/root'

// Resium
// import { KmlDataSource } from 'resium'

// Components
import OdpHeader from '../../components/Header/OdpHeader'
import OdpGlobalViewer from '../../components/GlobalViewer/OdpGlobalViewer'
import TrondheimFjordOverlay from '../../components/ImageryOverlays/TrondheimOverlay'

const Demo = () => {
  return (
    <>
      <OdpHeader />
      <OdpGlobalViewer full={true} position="absolute">
        <TrondheimFjordOverlay />
      </OdpGlobalViewer>
    </>
  )
}

export default hot(Demo)
