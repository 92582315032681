module.exports = [
  {
    label: 'Floaters',
  },
  {
    label: 'Moorings',
  },
  {
    label: 'Vessel',
  },
  {
    label: 'Tagged Animal',
  },
  {
    label: 'Satellite',
  },
  {
    label: 'Ocean Glider',
  },
]
