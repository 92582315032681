import React from 'react'
import { HashRouter, Route } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'

// Import from Ant Design
import { Layout } from 'antd'

// Import pages/route components
import Home from './pages/home/Home'
import Explore from './pages/explore/Explore'
import GreatBarrierReef from './pages/great-barrier-reef/GreatBarrierReef'
import GreatBarrierReefSecondary from './pages/great-barrier-reef/GreatBarrierReefSecondary'
import Demo from './pages/demo/demo'
import PageThreeTest from './pages/pagethree/PageThreeTest'

// Component config
const { Content } = Layout

function App() {
  return (
    <Layout>
      <Content>
        <HashRouter>
          <Route exact path="/" component={Home} />
          <Route exact path="/explore" component={Explore} />
          <Route
            path="/explore/great-barrier-reef"
            component={GreatBarrierReef}
          />
          <Route
            path="/explore/great-barrier-reef-secondary"
            component={GreatBarrierReefSecondary}
          />
          <Route exact path="/demo" component={Demo} />
          {/* Cesium/Resium testpage */}
          <Route path="/page-three-test" component={PageThreeTest} />
        </HashRouter>
      </Content>
    </Layout>
  )
}

export default hot(App)
