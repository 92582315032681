import React from 'react'
import styled from 'styled-components/macro'

// Import images
import { ReactComponent as OdpCollectDiyKits } from '../../assets/images/sidebar-icons/collect-diy-kits.svg'
import { ReactComponent as OdpDiverMeetupIcon } from '../../assets/images/sidebar-icons/diver-meetup-icon.svg'
import { ReactComponent as OdpLocalEventIcon } from '../../assets/images/sidebar-icons/local-event-icon.svg'
import { ReactComponent as OdpGetSupportIcon } from '../../assets/images/sidebar-icons/get-support-icon.svg'

const OdpCaseCommunityList = styled.ul`
  padding: 0;
  margin: 0 0 1rem 0;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
`

const OdpCaseCommunityListItem = styled.li`
  width: calc(25% - 0.5rem);
  text-align: center;

  :not(:last-child) {
    margin-right: 0.5rem;
  }
`

const OdpCaseCommunityFigure = styled.figure`
  width: 100%;
  height: 48px;
  margin: 0;

  svg {
    width: 100%;
    height: auto;
  }
`

const OdpCaseCommunityText = styled.p`
  margin: 0;
`

const OdpCaseCommunity = () => {
  return (
    <OdpCaseCommunityList>
      <OdpCaseCommunityListItem>
        <OdpCaseCommunityFigure>
          <OdpDiverMeetupIcon style={{ width: '48px' }} />
        </OdpCaseCommunityFigure>
        <OdpCaseCommunityText>Divers Meetup</OdpCaseCommunityText>
      </OdpCaseCommunityListItem>
      <OdpCaseCommunityListItem>
        <OdpCaseCommunityFigure>
          <OdpCollectDiyKits style={{ width: '28px' }} />
        </OdpCaseCommunityFigure>
        <OdpCaseCommunityText>
          Collect
          <br />
          DYI Kits
        </OdpCaseCommunityText>
      </OdpCaseCommunityListItem>
      <OdpCaseCommunityListItem>
        <OdpCaseCommunityFigure>
          <OdpLocalEventIcon style={{ width: '36px' }} />
        </OdpCaseCommunityFigure>
        <OdpCaseCommunityText>Local Events</OdpCaseCommunityText>
      </OdpCaseCommunityListItem>
      <OdpCaseCommunityListItem>
        <OdpCaseCommunityFigure>
          <OdpGetSupportIcon style={{ width: '36px' }} />
        </OdpCaseCommunityFigure>
        <OdpCaseCommunityText>Get Support</OdpCaseCommunityText>
      </OdpCaseCommunityListItem>
    </OdpCaseCommunityList>
  )
}

export default OdpCaseCommunity
