import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// Tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'
import * as colorBrandGreen from '../../assets/styles/tokens/token-color-brand-green.js'
import * as borderRadius from '../../assets/styles/tokens/token-border-radius'
import * as duration from '../../assets/styles/tokens/token-duration'

const OdpFormSwitchLabel = styled.label`
  width: 32px;
  height: 16px;
  position: relative;
  display: inline-block;
`

const OdpFormSwitchSlider = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  border-radius: ${borderRadius.BORDER_RADIUS_6};
  background-color: ${colorNeutral.COLOR_NEUTRAL_TINT_45};
  transition: ${duration.DURATION_3};

  ::before {
    width: 12px;
    height: 12px;
    content: '';
    position: absolute;
    left: 2px;
    bottom: 2px;
    background-color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
    border-radius: ${borderRadius.BORDER_RADIUS_6};
    transition: ${duration.DURATION_3};
  }
`

const OdpFormSwitchInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;

  :checked + ${OdpFormSwitchSlider} {
    background-color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
  }

  :focus + ${OdpFormSwitchSlider} {
    box-shadow: 0 0 1px ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
  }

  :checked + ${OdpFormSwitchSlider}:before {
    transform: translateX(16px);
  }

  :disabled + ${OdpFormSwitchSlider} {
    cursor: not-allowed;
    background-color: ${colorNeutral.COLOR_NEUTRAL_TINT_90};
  }
`

const OdpSwitch = props => {
  const { id, disabled, ...rest } = props

  return (
    <>
      <OdpFormSwitchLabel htmlFor={id}>
        <OdpFormSwitchInput
          id={id}
          type="checkbox"
          disabled={disabled}
          {...rest}
        />
        <OdpFormSwitchSlider />
      </OdpFormSwitchLabel>
    </>
  )
}

OdpSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default OdpSwitch
