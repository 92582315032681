import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components/macro'
import theme from 'styled-theming'

// Import tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral.js'
import * as colorBrandGreen from '../../assets/styles/tokens/token-color-brand-green.js'
import * as breakpoint from '../../assets/styles/tokens/token-breakpoints.js'
import * as borderRadius from '../../assets/styles/tokens/token-border-radius.js'
import * as zIndex from '../../assets/styles/tokens/token-z-index.js'

// Import images
import { ReactComponent as OdpLogo } from '../../assets/images/odf-logo.svg'

const colorSwitch = theme('mode', {
  lightMode: colorNeutral.COLOR_NEUTRAL_TINT_100,
  darkMode: colorNeutral.COLOR_NEUTRAL_TINT_15,
})

const OdpSiteHeader = styled.header`
  width: 100%;
  padding: 1rem 1rem 2rem 1rem;
  font-size: 1rem;
  position: absolute;
  z-index: ${zIndex.Z_INDEX_7};
  background: ${props =>
    props.gradientBg
      ? `linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 10%,
    rgba(0, 0, 0, 0) 80%
  )`
      : `transparent`};

  @media (min-width: ${breakpoint.BREAKPOINT_2}) {
    padding: 1.5rem 1.5rem 2rem 1.5rem;
  }

  @media (min-width: ${breakpoint.BREAKPOINT_3}) {
    padding: 2rem 4rem 2rem 4rem;
  }
`

const OdpSiteHeaderCol = styled.nav`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.flexEnd ? 'flex-end' : 'flex-start')};
  flex: 0 0 auto;
  float: left;
  position: relative;
`

const OdpSiteLogo = styled.div`
  margin-right: 2rem;

  svg {
    width: 120px;
    height: auto;
    fill: ${colorSwitch};

    @media (min-width: ${breakpoint.BREAKPOINT_2}) {
      width: 250px;
    }
  }
`

const OdpSiteHeaderListGroup = styled.ul`
  display: none;

  @media (min-width: ${breakpoint.BREAKPOINT_4}) {
    padding: 0;
    margin: 0;
    display: flex;
    list-style-type: none;
  }
`

const OdpSiteHeaderListGroupItem = styled.li`
  margin: 0 1.5rem;
`

const OdpSiteHeaderListGroupLink = styled(Link)`
  position: relative;
  color: ${colorSwitch};

  ::before {
    width: 100%;
    height: 1px;
    content: '';
    position: absolute;
    bottom: -1rem;
    background-color: transparent;
    transition: all 300ms ease;
  }

  :hover,
  :focus {
    color: ${colorSwitch};

    ::before {
      bottom: -0.5rem;
      background-color: ${colorSwitch};
    }
  }
`

const OdpSiteHeaderListGroupButton = styled(OdpSiteHeaderListGroupLink)`
  padding: 0.75rem 1.5rem;
  border-radius: ${borderRadius.BORDER_RADIUS_6};
  border: 1px solid ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
  transition: all 300ms ease;

  ::before {
    display: none;
  }

  :hover,
  :focus {
    color: ${colorNeutral.COLOR_NEUTRAL_TINT_15};
    border-color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
    background-color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
  }
`

const OdpHeader = () => {
  return (
    <ThemeProvider theme={{ mode: 'lightMode' }}>
      <OdpSiteHeader role="banner">
        <OdpSiteHeaderCol role="nav">
          <OdpSiteLogo>
            <Link to="/">
              <OdpLogo />
            </Link>
          </OdpSiteLogo>
          <OdpSiteHeaderListGroup>
            <OdpSiteHeaderListGroupItem>
              <OdpSiteHeaderListGroupLink to="/explore">
                Connect
              </OdpSiteHeaderListGroupLink>
            </OdpSiteHeaderListGroupItem>
            <OdpSiteHeaderListGroupItem>
              <OdpSiteHeaderListGroupLink to="">
                Visualise
              </OdpSiteHeaderListGroupLink>
            </OdpSiteHeaderListGroupItem>
            <OdpSiteHeaderListGroupItem>
              <OdpSiteHeaderListGroupLink to="">
                Analyse
              </OdpSiteHeaderListGroupLink>
            </OdpSiteHeaderListGroupItem>
          </OdpSiteHeaderListGroup>
        </OdpSiteHeaderCol>
        <OdpSiteHeaderCol flexEnd role="banner" style={{ top: '20px' }}>
          <OdpSiteHeaderListGroup>
            <OdpSiteHeaderListGroupItem>
              <OdpSiteHeaderListGroupLink to="">
                Join the community
              </OdpSiteHeaderListGroupLink>
            </OdpSiteHeaderListGroupItem>
            <OdpSiteHeaderListGroupItem>
              <OdpSiteHeaderListGroupButton to="">
                Sign in
              </OdpSiteHeaderListGroupButton>
            </OdpSiteHeaderListGroupItem>
          </OdpSiteHeaderListGroup>
        </OdpSiteHeaderCol>
      </OdpSiteHeader>
    </ThemeProvider>
  )
}

OdpSiteHeaderCol.propTypes = {
  flexEnd: PropTypes.bool,
}

export default OdpHeader
