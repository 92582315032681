export const COLOR_NEUTRAL_TINT_100 = '#ffffff' // White
export const COLOR_NEUTRAL_TINT_95 = '#f5f5f5'
export const COLOR_NEUTRAL_TINT_90 = '#e5e5e5'
export const COLOR_NEUTRAL_TINT_85 = '#d9d9d9'
export const COLOR_NEUTRAL_TINT_80 = '#cccccc'
export const COLOR_NEUTRAL_TINT_75 = '#bfbfbf'
export const COLOR_NEUTRAL_TINT_70 = '#b3b3b3'
export const COLOR_NEUTRAL_TINT_65 = '#a6a6a6'
export const COLOR_NEUTRAL_TINT_60 = '#999999'
export const COLOR_NEUTRAL_TINT_55 = '#8c8c8c'
export const COLOR_NEUTRAL_TINT_50 = '#7f7f7f'
export const COLOR_NEUTRAL_TINT_45 = '#737373'
export const COLOR_NEUTRAL_TINT_40 = '#666666'
export const COLOR_NEUTRAL_TINT_35 = '#595959'
export const COLOR_NEUTRAL_TINT_30 = '#4d4d4d'
export const COLOR_NEUTRAL_TINT_25 = '#404040'
export const COLOR_NEUTRAL_TINT_20 = '#333333'
export const COLOR_NEUTRAL_TINT_15 = '#262626' // Default text color
export const COLOR_NEUTRAL_TINT_10 = '#1a1a1a'
export const COLOR_NEUTRAL_TINT_5 = '#0d0d0d'
export const COLOR_NEUTRAL_TINT_0 = '#000000' // Black
