import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

// Tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral'
import * as colorBrandGreen from '../../assets/styles/tokens/token-color-brand-green.js'

const OdpCaseDataProvidersContainer = styled.div`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  border-radius: 4px;
  border: 1px solid ${colorNeutral.COLOR_NEUTRAL_TINT_75};
`

const OdpCaseDataProvidersImage = styled.figure`
  width: 80px;
  height: 80px;
  margin-right: 0.5rem;
  margin-bottom: 0;

  img {
    width: 80px;
    height: 80px;
  }
`

const OdpCaseDataProvidersContent = styled.div`
  padding: 0;
  margin: 0;
`

const OdpCaseDataProvidersTitle = styled.h3`
  padding-right: 30px;
  margin: 0;
  position: relative;
  font-size: 12px;
  font-weight: 600;
`

const OdpCaseDataProvidersIcon = styled.div`
  width: 12px;
  height: 12px;
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2px;
  right: -2px;
  border-radius: 9999px;
  border: 1px solid ${colorNeutral.COLOR_NEUTRAL_TINT_75};

  span {
    width: 1px;
    height: 1px;
    border-radius: 9999px;
    background-color: ${colorNeutral.COLOR_NEUTRAL_TINT_75};

    :not(:last-child) {
      margin-right: 1px;
    }
  }
`

const OdpCaseDataProvidersText = styled.p`
  width: 100%;
  margin-bottom: 0;
  font-size: 8px;
  word-wrap: normal;
`

const OdpCaseDataProvidersLink = styled.a`
  font-size: 8px;
  word-break: break-all;
  color: ${colorBrandGreen.COLOR_BRAND_GREEN_STATIC};
`

const OdpCaseDataProviders = props => {
  const { image, title, text, link } = props

  return (
    <OdpCaseDataProvidersContainer>
      <OdpCaseDataProvidersImage>
        <img src={image} alt="alt" />
      </OdpCaseDataProvidersImage>
      <OdpCaseDataProvidersContent>
        <OdpCaseDataProvidersTitle>
          {title}
          <OdpCaseDataProvidersIcon>
            <span></span>
            <span></span>
            <span></span>
          </OdpCaseDataProvidersIcon>
        </OdpCaseDataProvidersTitle>
        <OdpCaseDataProvidersText>{text}</OdpCaseDataProvidersText>
        <OdpCaseDataProvidersLink href={link}>{link}</OdpCaseDataProvidersLink>
      </OdpCaseDataProvidersContent>
    </OdpCaseDataProvidersContainer>
  )
}

// OdpCaseDataProviders.defaultProps = {}

OdpCaseDataProviders.propTypes = {
  image: PropTypes.node,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
}

export default OdpCaseDataProviders
