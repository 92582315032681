import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
// Ant Design
import { List, Icon } from 'antd'
// Components
import OdpCard from '../Card/OdpCard'

const Wrapper = styled.div`
  padding-top: 3rem;
`

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const ListTitle = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
`

const ActionTitle = styled.p`
  opacity: 0.7;

  font-size: 0.875rem;
`

const OdpCardsList = props => {
  const { showHeader, listTitle, actionTitle, actionUrl, cards } = props

  const renderListHeader = () => {
    if (showHeader) {
      return (
        <ListHeader>
          <div>
            <ListTitle>{listTitle.toUpperCase()}</ListTitle>
          </div>
          <div>
            <ActionTitle>
              <Link to={actionUrl}>
                {actionTitle} <Icon type="right" />
              </Link>
            </ActionTitle>
          </div>
        </ListHeader>
      )
    } else {
      return null
    }
  }

  return (
    <Wrapper>
      {renderListHeader()}
      <List
        grid={{ gutter: 16 * 2, column: cards ? cards.length : null }}
        dataSource={cards}
        renderItem={item => (
          <List.Item>
            <OdpCard
              url={item.url}
              imgUrl={item.imgUrl}
              imgAlt={item.imgAlt}
              headerText={item.headerText}
              subHeaderText={item.subHeaderText}
              supportingText={item.supportingText}
            />
          </List.Item>
        )}
      />
    </Wrapper>
  )
}

OdpCardsList.defaultProps = {
  showHeader: true,
  listTitle: 'Trending',
  listIcon: 'rise',
  actionTitle: 'View All',
  actionUrl: '/',
}

OdpCardsList.propTypes = {
  header: PropTypes.bool,
  listTitle: PropTypes.string,
  listIcon: PropTypes.string,
  actionTitle: PropTypes.string,
  actionUrl: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default OdpCardsList
