import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { keyFrameFadeIn } from '../KeyFrames/OdpKeyframe'

// Import tokens
import * as colorNeutral from '../../assets/styles/tokens/token-color-neutral.js'
import * as breakpoint from '../../assets/styles/tokens/token-breakpoints.js'
import * as fontWeight from '../../assets/styles/tokens/token-font-weight'

const BoxContainer = styled.div`
  width: calc(100% - 1rem);
  padding: 1.5rem 0.75rem;
  /* margin-left: -180px; */
  color: ${colorNeutral.COLOR_NEUTRAL_TINT_100};
  text-align: center;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: calc(50% + 171px);
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${keyFrameFadeIn} 0.3s ease-in-out 0s forwards;

  @media (min-width: ${breakpoint.BREAKPOINT_2}) {
    max-width: 22.5rem;
  }
`

const BoxTitle = styled.div`
  margin-bottom: 0.5rem;
  font-weight: ${fontWeight.FONT_WEIGHT_5};
`

const OdpBox = ({ title, children }) => {
  return (
    <BoxContainer>
      <BoxTitle>{title}</BoxTitle>
      {children}
    </BoxContainer>
  )
}

OdpBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default OdpBox
